html,
body {
  -webkit-font-smoothing: antialiased;
  color: black;
  font-weight: 300;
  line-height: 20px;
  height: 100%;
  width: 100%;
  /* font-size: 20px; */
  background: #011a2e !important;
  box-shadow: 0px 4px 4px rgba(27, 44, 55, 0.5);
  touch-action: manipulation;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                              supported by Chrome, Edge, Opera and Firefox */
}

#top-bar {
  background: #141d33 !important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 45px;
  box-shadow: 0px 4px 4px rgb(27 44 55 / 50%);
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  font-variant: small-caps;
  color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title h1 {
  font-size: 17px;
  margin-top: 12px;
  text-transform: uppercase;
  color: #fff;
  font-family: PFBeauSansPro-Bold;
}

.back {
  position: absolute;
  top: 8px;
  left: 0;
  padding: 5px 20px;
  z-index: 98;
  cursor: pointer;
}

#page-content {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#content-body {
  position: relative;
  top: 45px;
  height: calc(var(--vh, 1vh) * 100 - 45px);
  background: #0c111d;
}

@keyframes flicker {
  0%,
  100% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
}

@-o-keyframes flicker {
  0%,
  100% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
}

@-moz-keyframes flicker {
  0%,
  100% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
}

@-webkit-keyframes flicker {
  0%,
  100% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
}

.animate-flicker {
  -webkit-animation: flicker 2s infinite;
  -moz-animation: flicker 2s infinite;
  -o-animation: flicker 2s infinite;
  animation: flicker 2s infinite;
}

.common-bg-box {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader-box {
  position: relative;
  text-align: center;
}

.preloader-box-loading-box {
  width: 300px;
  height: 184.5px;
  left: 37.5px;
  top: 153.75px;
}

.icon-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.error-pic-box-2 {
  height: 130px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  opacity: 0.5;
}

.error-text {
  position: absolute;
  width: 300px;
  height: 20px;
  left: 0.25px;
  top: 137.34px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #7f8d9f;
}

.error-main-box {
  position: absolute;
  width: 300px;
  height: 25px;
  left: 0.25px;
  top: 161.69px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7f8d9f !important;
}

/* Big Screens with small controls */
@media (min-width: 767px) {
  #page-content {
    margin: 0 auto;
    max-width: 600px;
  }
}
