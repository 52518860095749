#content-body {
  top: 0 !important;
  height: calc(var(--vh, 1vh) * 100) !important;
}

.error-pic-box-2 {
  width: 90px;
  height: 90px !important;
  margin: auto;
}

.error-pic-box-2 img {
  width: 75%;
}

.error-text {
  top: 110px;
}

.error-main-box {
  top: 130px;
}

.session-error-box {
  font-size: 11px;
}
